import { PROFILEBUILDER_API_URL } from "../constants";
import { axiosApi } from "../../../../common-ui-modules/service/redux-helper/middleware/axios-api";
import { IS_PUBLIC } from "../../common/constants";

export const UpdateUserInfo = (
    currentData,
    existingData,
    supportAddress,
    profileBuilderData,
    path,
) => {
    let currentValues = {};
    const currSupportId = [];
    profileBuilderData.map((item) => {
        if (item.isRequested || item.defaultChecked) {
            currSupportId.push(item.supportValue);
        }
    });
    //let supportId = [...existingData.supportId, ...currSupportId]
    const supportId = currSupportId;
    currentValues = structureCurrentValues(currentData, supportAddress, supportId);

    let data = {};
    data = {
        firstName: existingData.firstName,
        lastName: existingData.lastName,
        jobTitle: currentValues.jobTitle ? currentValues.jobTitle : existingData.jobTitle,
        phone: currentValues.phone ? currentValues.phone : existingData.phone,
        // timezone: currentValues.timezone ? currentValues.timezone : existingData.timezone,
        addressLine1: currentValues.address ? currentValues.address : existingData.addressLine1,
        addressLine2: currentValues.address2 ? currentValues.address2 : existingData.addressLine2,
        city: currentValues.city ? currentValues.city : existingData.city,
        stateName: currentValues.state ? currentValues.state : existingData.state,
        postalCode: currentValues.zipcode ? currentValues.zipcode : existingData.postalCode,
        countryId: currentValues.country
            ? currentValues.country
            : existingData.country.countryCode
            ? existingData.country.countryCode
            : existingData.country,
        customerId: existingData.customerId,
        companyId: currentValues?.companyId
            ? currentValues.companyId
            : existingData?.company && existingData?.company?.companyId
            ? existingData.company.companyId
            : 5000000,
        otherCompanyName: currentValues.tapCompanyName ? currentValues.tapCompanyName : (currentValues.companyName
            ? currentValues.companyName
            : existingData.company.otherCompanyName),
        supportId: supportId,
        website: currentValues.website ? currentValues.website : existingData.website,
        sourceId: existingData.sourceId,
        registrationId: 2,
        uuid: null,
        siteId: currentValues.siteId ? currentValues.siteId : existingData.siteId,
        reregisterUser: false,
        autoScreened: false,
        companyVerified: false,
        broadcomContactName: "",
        broadcomContactEmail: currentValues.broadcomcontact
            ? currentValues.broadcomcontact
            : existingData.broadcomContactEmail,
        updateSpecificFields: false,
        billToCustomerId: currentValues.customerId ? currentValues.customerId : null,
        bsnAutoApproved: currentValues.bsnAutoApproved ? currentValues.bsnAutoApproved : false,
        source: "PORTAL",
    };

    if (currentValues.serialNumber) {
        data.serialNumber = currentValues.serialNumber;
    }

    const headers = {
        "Content-Type": "application/json",
    };
    return axiosApi({
        url: IS_PUBLIC
            ? PROFILEBUILDER_API_URL.UPGRADE_USER_INFO_WEB
            : PROFILEBUILDER_API_URL.UPGRADE_USER_INFO,
        method: "POST",
        headers,
        data: JSON.stringify(data),
    }).then((response) => {
        return response.data;
    });
};

function structureCurrentValues(currentData, supportAddress, supportId) {
    const currentValues = {};

    if (supportId.length === 1 && supportId[0] === 1 && supportAddress) {
        currentValues.address = supportAddress.addressLine1;
        currentValues.city = supportAddress.city;
        currentValues.companyName = supportAddress.companyName;
        currentValues.country = supportAddress.countryId;
        currentValues.zipcode = supportAddress.postalCode;
        currentValues.state = supportAddress.state;
    }
    currentData.map((item) => {
        if (item.isRequested && !item.defaultChecked) {
            Object.keys(item.data).map((key) => {
                if (item.data[key] && !Object.prototype.hasOwnProperty.call(currentValues, key)) {
                    currentValues[key] = item.data[key];
                }
            });
        }
    });
    currentValues.supportId = supportId;
    return currentValues;
}
