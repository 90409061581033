import {
    SOFTWARE_SECTION_IDX,
    BSN_SECTION_IDX,
    PARTNER_SECTION_IDX,
    ESTORE_SECTION_IDX,
    SEMICONDUCTOR_SECTION_IDX,
    TAP_PARTNER_SECTION_IDX,
    SECTION_REQUESTED_ARRAY,
    OTHER_COMPANY_ID,
} from "../../../../modules/Registration/ui/common/constants";

function formatUserInfoVO(obj, profileBuilderData) {
    const userProfile = {};
    if (obj.userVO) {
        const {
            bsnApprovalStatus,
            estoreStatus,
            partnerStatus,
            semiconductorStatus,
            supportStatus,
            tapStatus
        } = obj;
        const sectionRequestedArray = SECTION_REQUESTED_ARRAY;

        profileBuilderData[SOFTWARE_SECTION_IDX].defaultChecked =
            sectionRequestedArray.includes(supportStatus);
        profileBuilderData[BSN_SECTION_IDX].defaultChecked =
            sectionRequestedArray.includes(bsnApprovalStatus);
        profileBuilderData[PARTNER_SECTION_IDX].defaultChecked =
            sectionRequestedArray.includes(partnerStatus);
        profileBuilderData[ESTORE_SECTION_IDX].defaultChecked =
            sectionRequestedArray.includes(estoreStatus);
        profileBuilderData[SEMICONDUCTOR_SECTION_IDX].defaultChecked =
            sectionRequestedArray.includes(semiconductorStatus);
        profileBuilderData[TAP_PARTNER_SECTION_IDX].defaultChecked =  
            sectionRequestedArray.includes(tapStatus);

        userProfile.firstName = obj.userVO.firstName ? obj.userVO.firstName : "";
        userProfile.lastName = obj.userVO.lastName ? obj.userVO.lastName : "";
        userProfile.email = obj.userVO.email ? obj.userVO.email : "";
        userProfile.jobTitle = obj.userVO.jobTitle ? obj.userVO.jobTitle : "Other";
        userProfile.phone = obj.userVO.phone ? obj.userVO.phone : "";
        userProfile.addressLine1 = obj.userVO.addressLine1 ? obj.userVO.addressLine1 : "";
        userProfile.addressLine2 = obj.userVO.addressLine2 ? obj.userVO.addressLine2 : "";
        userProfile.city = obj.userVO.city ? obj.userVO.city : "";
        userProfile.state = obj.userVO.stateName ? obj.userVO.stateName : "";
        userProfile.postalCode = obj.userVO.postalCode ? obj.userVO.postalCode : "";
        userProfile.isEnterprise = obj.userVO.gtmScreened === "Y" ? true : false;
        userProfile.country = obj.userVO.country ? obj.userVO.country.countryId : "";
        userProfile.countryName = obj.userVO.country ? obj.userVO.country.countryName : "";
        userProfile.customerId = obj.userVO.id ? obj.userVO.id : "";
        userProfile.supportId = obj.userVO.supportId ? obj.userVO.supportId : [];
        userProfile.sourceId = obj.userVO.sourceId ? obj.userVO.sourceId : [];
        userProfile.registrationId = obj.userVO.registrationId ? obj.userVO.registrationId : null;
        userProfile.companyId = obj.userVO.company ? obj.userVO.company.companyId : null;
        userProfile.companyName = obj.userVO.company ? obj.userVO.company.companyName : null;
        userProfile.website = obj.userVO.website ? obj.userVO.website : null;
        userProfile.timezone = obj.userVO.timezone ? obj.userVO.timezone : "";
        userProfile.otherCompanyName = obj.userVO.otherCompanyName
            ? obj.userVO.otherCompanyName
            : null;
        userProfile.isBSNAssistUser = obj?.userVO?.isBSNAssistUser || false;
        userProfile.siteId = obj?.userVO?.siteId || "";
        userProfile.newTapDomain = obj?.newTapDomain || false;
        const companyVO = {};
        const list = [];

        const companyId = userProfile.companyId;

        if (obj.companyVO) {
            obj.companyVO.map((item) => {
                list.push({ label: item.companyName, value: item.companyId });
            });
        }

        if (companyId === OTHER_COMPANY_ID && obj.userVO.otherCompanyName) {
            companyVO.isDisabled = true;
            companyVO.fieldType = "text";
        } else if (companyId === OTHER_COMPANY_ID) {
            //if company list contains, company other than "other", show drop down
            if (list.length && list[0].value !== OTHER_COMPANY_ID) {
                companyVO.fieldType = "select";
            } else {
                if (userProfile.companyName === "Other") {
                    userProfile.companyName = "";
                }
                companyVO.fieldType = "text";
            }
            companyVO.isDisabled = false;
            companyVO.companyId = companyId;
        } else if (companyId === "") {
            if (obj.companyVO) {
                companyVO.isDisabled = false;
                companyVO.fieldType = "select";
                companyVO.companyId = companyId;
            } else {
                companyVO.isDisabled = false;
                companyVO.fieldType = "text";
                companyVO.companyId = companyId;
            }
        } else {
            companyVO.isDisabled = true;
            companyVO.fieldType = "select";
            companyVO.companyId = companyId;
        }
        companyVO.options = list;
        userProfile.company = obj.userVO.company ? companyVO : {};
    }
    return userProfile;
}

export default formatUserInfoVO;
