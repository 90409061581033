import {
    SERVICES_ARRAY,
    BROADCOM_SOFTWARE_SUPPORT_SYSTEMS_IDX,
    BROADCOM_SOFTWARE_CASE_MGMT_IDX,
    BROADCOM_SOFTWARE_LICENSING_IDX,
    BROADCOM_SOFTWARE_PRODUCT_DOWNLOADS_IDX,
    BROADCOM_SOFTWARE_PREMIUM_LEARNING_IDX,
    BROADCOM_STORAGE_NETWORKING_SUPPORT_SYSTEMS_IDX,
    BROADCOM_STORAGE_NETWORKING_CASE_MGMT_IDX,
    BROADCOM_STORAGE_NETWORKING_LICENSING_IDX,
    BROADCOM_STORAGE_NETWORKING_PRODUCT_DOWNLOADS_IDX,
    BROADCOM_STORAGE_NETWORKING_PREMIUM_LEARNING_IDX,
    SEMICONDUCTORS_SUPPORT_SYSTEMS_IDX,
    SEMICONDUCTORS_CASE_MGMT_IDX,
    SEMICONDUCTORS_PRODUCT_DOWNLOADS_IDX,
    BROADCOM_PARTNER_IDX,
    ESTORE_IDX,
    PREMIUM_EDUCATION_IDX,
    ISUPPLIER_IDX,
    TAP_PARTNER_IDX,
    BSN_AUTO_APPROVED,
    BSN_AUTO_APPRVL_REQD,
    // BROADCOM_SCRT_REPORT_MANAGEMENT_IDX
} from "../../../../modules/Registration/ui/common/constants";

//TODO use interface
export const updateServiceArray = (obj: any) => {
    const SERVICES_ARRAY_COPY = JSON.parse(JSON.stringify(SERVICES_ARRAY));
    const {
        bsnApprovalStatus,
        estoreStatus,
        isupplierStatus,
        partnerStatus,
        semiconductorStatus,
        supportStatus,
        tapStatus
    } = obj;
    const isGTMScreened = obj.userVO
        ? obj.userVO.gtmScreened === "Y"
        : obj.gtmScreened
        ? obj.gtmScreened === "Y"
        : false;
    const bsnAutoApproved = obj.userVO ? obj.userVO.bsnAutoApproved : false;
    const isBSN = obj?.isBSN ? obj.isBSN : obj.userVO ? obj.userVO.isBSN : null;

    if (partnerStatus) {
        //partner Portal pending
        SERVICES_ARRAY_COPY[BROADCOM_PARTNER_IDX].status = partnerStatus.toLowerCase();
        if (partnerStatus.toLowerCase() === "rejected") {
            SERVICES_ARRAY_COPY[BROADCOM_PARTNER_IDX].status = "disabled";
        }
    }
    if (estoreStatus) {
        //make eStore visible and pending, partner portal green
        SERVICES_ARRAY_COPY[ESTORE_IDX].status = estoreStatus.toLowerCase();
    }
    if (tapStatus) {
        SERVICES_ARRAY_COPY[TAP_PARTNER_IDX].status = tapStatus.toLowerCase();
    }
    if (supportStatus) {
        SERVICES_ARRAY_COPY[BROADCOM_SOFTWARE_SUPPORT_SYSTEMS_IDX].status = "removed";
        SERVICES_ARRAY_COPY[PREMIUM_EDUCATION_IDX].status = "removed";
        SERVICES_ARRAY_COPY[BROADCOM_SOFTWARE_CASE_MGMT_IDX].status = supportStatus.toLowerCase();
        SERVICES_ARRAY_COPY[BROADCOM_SOFTWARE_LICENSING_IDX].status = supportStatus.toLowerCase();
        SERVICES_ARRAY_COPY[BROADCOM_SOFTWARE_PREMIUM_LEARNING_IDX].status =
            supportStatus.toLowerCase();
        SERVICES_ARRAY_COPY[BROADCOM_SOFTWARE_PRODUCT_DOWNLOADS_IDX].status =
            supportStatus.toLowerCase();
        if (supportStatus.toLowerCase() === "rejected") {
            SERVICES_ARRAY_COPY[BROADCOM_SOFTWARE_SUPPORT_SYSTEMS_IDX].status = "disabled";
        }
    }

    if (bsnApprovalStatus) {
        SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_SUPPORT_SYSTEMS_IDX].status = "removed";
        SERVICES_ARRAY_COPY[PREMIUM_EDUCATION_IDX].status = "removed";
        if (isBSN !== BSN_AUTO_APPROVED && isBSN !== BSN_AUTO_APPRVL_REQD) {
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_CASE_MGMT_IDX].status =
                bsnApprovalStatus.toLowerCase();
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_PRODUCT_DOWNLOADS_IDX].status =
                bsnApprovalStatus.toLowerCase();
        }
        if (isGTMScreened || bsnAutoApproved) {
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_LICENSING_IDX].status = "approved";
        } else if (!isGTMScreened && isBSN === BSN_AUTO_APPRVL_REQD) {
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_LICENSING_IDX].status = "pending";
        }

        if (bsnApprovalStatus.toLowerCase() === "approved" || bsnAutoApproved) {
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_PREMIUM_LEARNING_IDX].status =
                "approved";
        } else {
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_PREMIUM_LEARNING_IDX].status =
                bsnApprovalStatus.toLowerCase();
        }
        if (bsnApprovalStatus.toLowerCase() === "rejected") {
            SERVICES_ARRAY_COPY[BROADCOM_STORAGE_NETWORKING_SUPPORT_SYSTEMS_IDX].status =
                "disabled";
        }
    }

    if (semiconductorStatus === "APPROVED" || semiconductorStatus === "PENDING") {
        SERVICES_ARRAY_COPY[SEMICONDUCTORS_SUPPORT_SYSTEMS_IDX].status = "removed";
        SERVICES_ARRAY_COPY[SEMICONDUCTORS_CASE_MGMT_IDX].status =
            semiconductorStatus.toLowerCase();
        SERVICES_ARRAY_COPY[SEMICONDUCTORS_PRODUCT_DOWNLOADS_IDX].status =
            semiconductorStatus.toLowerCase();
    }
    if (isupplierStatus) {
        //make iSupplier visible and pending
        SERVICES_ARRAY_COPY[ISUPPLIER_IDX].status = isupplierStatus.toLowerCase();
        //SERVICES_ARRAY_COPY.push({ "title": "iSupplier", "status": "pending" })
    }
    return SERVICES_ARRAY_COPY;
};
